<template>
    <div :class="containerClass">
        <input type="hidden" :name="name" :id="id" :value="selectedValue">
        <span class="rating-option"
            v-for="(item, index) in options" 
            v-on:click="selectItem(index)"
        >
            <span class="fa " v-bind:class="{ 'fa-star': isMarked(index), 'fa-star-o': !isMarked(index) }"></span>
        </span>
    </div>
</template>

<script>
    export default {
        props: {
            name: {
              type: String,
              default: ''
            },
            preselected: {
              type: Number,
              default: null
            },
            id: {
              type: String,
              default: ''
            },
            containerClass: {
              type: String,
              default: ''
            },
            options: {
              type: Array
            }
        },
        mounted() {
            //console.log('Component mounted.')
        },
        data: function () {
          return {
            selected: this.preselected
          }
        },
        computed: {
            selectedValue: function () {
                return this.selected !== null ? this.options[this.selected] : '';
            }
        },
        methods: {
            selectItem (index) {
                this.selected = index;
            },
            isSelected (index) {
                return (this.selected == index);
            },
            isMarked(index){
                return this.selected !== null && this.selected >= index;
            }
        }
    }
</script>
