require('./bootstrap');
require('jquery.are-you-sure');
window.moment = require('moment');

import Vue from 'vue';
window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('bs4-typeahead', require('./components/bs4-controls/Typeahead.vue').default);
Vue.component('bs4-tag-input', require('./components/bs4-controls/TagInput.vue').default);
Vue.component('bs4-datepicker', require('./components/bs4-controls/Datepicker.vue').default);
Vue.component('bs4-search-field', require('./components/bs4-controls/SearchField.vue').default);
Vue.component('rating-field', require('./components/Rating.vue').default);

import Sortable from 'vue-sortable';
Vue.use(Sortable);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    methods: {
        sortableUpdate: function (evt) {
                let sort_form = $(evt.srcElement).data('tgt');
                $(sort_form).find('input[name^="order"]').remove();
                $(evt.srcElement).children().each(function(e){
                    $( '<input name="order[]" value="' + $(this).data('id') + '" type="hidden">' ).appendTo( sort_form );
                });
                $(sort_form).removeClass('d-none');
        }
    }
});

		$(function() {
    		$(':checkbox.shift-exclusive').click(function (e){
    		    if(e.shiftKey) {
                    var src = this;
                    $(this).parents('form').find(':checkbox[name="' + $(this).attr('name') +  '"]').each(function(cb) {
                        if(this != src){
                            $(this).prop( "checked", !$(src).prop( "checked") );
                        }
                    });
    		    }
			});

			$('input.file-input-custom, input.autosubmit, select.autosubmit').change(function (e){

    		    if(this.files && $(this).data('limit')){

    		        let upload_limit = $(this).data('limit')*1000;
    		        let upload_size = 0;

    		        for(const file of this.files){
    		            upload_size = parseInt(file.size)/1024;
                        if(upload_size > upload_limit) {
                            alert(file.name + ' ist leider zu groß.');
                            return;
                        }
    		        }
    		    }

				$(this).parents('form').submit();
			});

			$('form.dirty-check').areYouSure( {'message':'Änderungen verwerfen?'} );
            $('.collapse.exclusive').on('show.bs.collapse', function () {
                $('.collapse.exclusive').collapse('hide');
            })
		});
